import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
require("./Contact.css");
const image = require('../assets/images/carousel-2.jpg');

const ContactForm = () => {
    const [formData, setFormData] = useState({
        fullname: '',
        mobile_number: '',
        email: '',
        subject: '',
        message: ''
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Validate email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                alert('Invalid email');
                return;
            }

            // Validate mobile number length
            if (formData.mobile_number.length !== 10) {
                alert('Invalid mobile number');
                return;
            }

            // Form submission

            const response = await axios.post('http://backend.vermadesign.in/api/contact/', formData);
            console.log('Response:', response.data);
            alert('Form submitted successfully');
            navigate('/');
        }
        catch (error) {
            console.error('Error:', error);
            alert('Form submission failed. Please try again later.');
        }
    };
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                        <h1 className="mb-3 mt-5">Contact Us</h1>
                        <p>Connect with us and let's turn your inquiries into vibrant conversations. </p>
                    </div>
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="row gy-4">
                                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                                    <div className="bg-light rounded p-3">
                                        <div className=" d-flex align-items-center bg-white rounded p-3" id='concustum' style={{ border: '1px dashed rgba(0, 185, 142, .3)', height: '135px' }}>
                                            {/* <div className="icon me-3" style={{ width: '45px', height: '135px' }}>
                                                <i className="fa fa-map-marker-alt text-primary"></i>
                                            </div> */}
                                            <div style={{ display: "flex", flexDirection: "column", fontSize: "12.5px" }}>
                                                <a target='blank' rel='noreffer' href="https://maps.app.goo.gl/h5NJvskhicrumr5M8"><p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>HEAD OFFICE:-99, Dayanand Colony, Opp. Sec-5 Police station, Gurugram, HR, 122001</p></a>
                                                <a target='blank' rel='noreffer' href="https://www.google.com/maps/place/Lilac-c/@26.8094588,81.0023569,19z/data=!4m7!3m6!1s0x399be34411e42553:0xad4fedc12455459c!4b1!8m2!3d26.8093732!4d81.0029571!16s%2Fg%2F11gdvwwlqs?entry=ttu"><p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>BRANCH OFFICE :-202, Lilac-C, Omaxe Residancy/1, Gomti Nagar Extn., Lucknow, U.P, 226010</p></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                                    <div className="bg-light rounded p-3">
                                        <div className="d-flex align-items-center bg-white rounded p-3" style={{ border: '1px dashed rgba(0, 185, 142, .3)' }}>
                                            {/* <div className="icon me-3" style={{ width: '45px', height: '135px' }}>
                                                <i className="fa fa-envelope-open text-primary"></i>
                                            </div> */}
                                            <div style={{ display: "flex", flexDirection: "column", fontSize: "12.5px" }}>
                                                <a href="mailto:vermadesignheadoffice@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>vermadesignheadoffice@gmail.com</p></a>
                                                <a href="mailto:verma.design@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>verma.design@gmail.com</p></a>
                                                <a href="mailto:ar.vdc90@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>ar.vdc90@gmail.com</p></a>
                                                <a href="mailto:vermahement90@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>vermahement90@gmail.com</p></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-md-6 col-lg-4 wow fadeIn " data-wow-delay="0.5s">
                                    <div className="bg-light d-block  rounded p-3 ">
                                        <div className="d-flex align-items-center bg-white rounded p-3 con" style={{ border: '1px dashed rgba(0, 185, 142, .3)', height: '135px' }}>
                                            {/* <div className="icon me-3" style={{ width: '45px', height: '135px' }}>
                                                <i className="fa fa-phone-alt text-primary"></i>
                                            </div> */}
                                            <div style={{ display: "flex", flexDirection: "column", fontSize: "13px" }}>
                                                <a href="tel:+919311257550"   ><p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91-9311257550</p></a>
                                                <a href="tel:+918400215000"   ><p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91-8400215000</p></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                </div>
                            </div>
                        </div>
                        <div className='con-container'>
                            <div className="col-md-6 wow fadeInUp " id='contactimg' data-wow-delay="0.1s">
                                <img src={image} alt="" style={{ maxWidth: '480px', maxHeight: '400px' }} />
                            </div>
                            <div className="col-md-6" id='formdiv'>
                                <div className="wow fadeInUp" data-wow-delay="0.5s">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="text" name="fullname" className="form-control" id="name" value={formData.fullname} onChange={handleChange} placeholder="Your Name" />
                                                    <label htmlFor="email">Full name</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="email" name="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Your Email" />
                                                    <label htmlFor="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating">
                                                    <input type="tel" name="mobile_number" className="form-control" value={formData.mobile_number} onChange={handleChange} id="mobile" placeholder="Mobile" />
                                                    <label htmlFor="subject">Mobile</label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-floating">
                                                    <input type="text" name="subject" className="form-control" value={formData.subject} onChange={handleChange} id="subject" placeholder="Subject" />
                                                    <label htmlFor="subject">Subject</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating ">
                                                    <textarea className="form-control" name="message" value={formData.message} onChange={handleChange} placeholder="Leave a message here" id="message" style={{ height: '150px' }}></textarea>
                                                    <label htmlFor="message">Message</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContactForm;
