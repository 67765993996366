
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
require( "./GalleryImageCarousel.css")

function Carousel({ images, onBack }) {


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    className: 'slider3'
  };

  return (
    <div className='gallery-sitepic'>
      <button onClick={onBack}>Back</button>
      <Slider {...settings}>
        {images.map(image => (
          <div key={image.id}>
            {console.log(image.id)}
            {console.log(image)}
            <img src={`http://backend.vermadesign.in${image.image}`} alt={`ID: ${image.id}`} />
          </div>
        ))}
      </Slider>
  
    </div>
  );
}

export default Carousel;
