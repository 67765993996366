import React, { useState, useEffect } from 'react';
import aboutImage from '../assets/images/vdc_logo.jpeg';
require( "./Gallery.css");
const Form = () => {
    const [siteName, setSiteName] = useState('');
    const [mainImage, setMainImage] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [images, setImages] = useState([]);
    useEffect(() => {
        // Fetch categories from API when component mounts
        fetchCategories();
    }, []);
    const fetchCategories = async () => {
        try {
            const response = await fetch('http://backend.vermadesign.in/api/gallerycatagory/');
            if (response.ok) {
                const data = await response.json();
                setCategories(data); // Assuming data is an array of category objects
            } else {
                console.error('Failed to fetch categories');
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('SiteName', siteName);
            formData.append('MainImage', mainImage);
            formData.append('category', category);
            images.forEach((image, index) => {
                formData.append(`image${index}`, image);

            });
            const response = await fetch('http://backend.vermadesign.in/api/galleryForm/', {
                method: 'POST',
                body: formData

            });
            if (response.ok) {
                console.log('Form submitted successfully!');
                // Optionally, reset the form fields
                setSiteName('');
                setMainImage('');
                setCategory('');
                setImages([]);
            } else {
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    const handleImageChange = (event) => {
        const files = event.target.files;
        // Convert FileList to an array
        const imageFiles = Array.from(files);
        setImages(imageFiles);

    };
    return (
        <div className='formpage-container'>
            <div className='formpage-img'>
                <div className="about-img position-relative overflow-hidden p-5 pe-0">
                    <img className="img-fluid11 " src={aboutImage} alt="" />
                </div>
            </div>
            <div className='formpage-form'>
                <form onSubmit={handleSubmit} className="mt-4" >
                    <div className="form-group">
                        <label htmlFor="siteName">Site Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="siteName"
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mainImage">Main Image:</label>
                        <input
                            type="file"
                            className="form-control"
                            id="mainImage"
                            value={mainImage}
                            onChange={(e) => setMainImage(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="category">Category:</label>
                        <select
                            className="form-control"
                            id="category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="">Select Category</option>
                            {categories.map(cat => (
                                <option key={cat.id} value={cat.id}>{cat.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="sameSiteImages">Same Site Images:</label>
                        <input
                            type="file"
                            className="form-control-file"
                            id="sameSiteImages"
                            multiple
                            onChange={handleImageChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    );
};
export default Form;
