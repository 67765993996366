import React from 'react';
import aboutImage from '../assets/images/property-1.jpg';
function AboutSection() {
  return (
    <div className="container-xxl py-5 ">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s"  >
            <div className="about-img position-relative overflow-hidden p-5 pe-0">
              <img className="img-fluid w-100" src={aboutImage} alt="" />
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <h1 className="mb-4"> VERMA DESIGN & CONSULTANT</h1>
            <p className="mb-4">VERMA DESIGN & CONSULTANT is an architectural and interior designing firm located in Gurgaon, Haryana and founded by Mr. O.P. Verma and started its business as sole proprietorship firm in 1996. The firm provides comprehensive services in architectural, engineering consultancy, interior design & construction of all building works encompassing all aspects of the building industry from project identification and conceptual planning to detailed designing, building services, co-ordination and construction monitoring, which are relevant to the demands of the project and client needs</p>
            {/* <p><i className="fa fa-check text-primary me-3"></i>We design Future House for you </p>
            <p><i className="fa fa-check text-primary me-3"></i>Vastu and future design is our priority</p>
            <p><i className="fa fa-check text-primary me-3"></i>We help you to Estimate the total funds</p> */}
            <a exact="true" className="btn btn-primary py-3 px-5 mt-3 " href="/about">Read More</a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutSection;
