import React, { useState, useEffect } from 'react';
import Pagination from './Pagination';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from './GalleryImageCarousel';
require("./Gallery.css");

function Gallery() {
    const [images, setImages] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [showCarousel, setShowCarousel] = useState(false);
    const [carouselImages, setCarouselImages] = useState([]);
    const [dataPerPage, setDataPerPage] = useState(20);
    const [leftPanelDisplay, setLeftPanelDisplay] = useState('flex');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('http://backend.vermadesign.in/api/gallery/');
            const data = await response.json();
            setImages(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCategoryClick = (categoryName) => {
        setSelectedCategory(categoryName);
        setCurrentPage(0);
    };

    const handleImageClick = async (category, site) => {
        setShowCarousel(true);
        setLeftPanelDisplay('none'); // Set left panel display to 'none' when image is clicked
        try {
            const response = await fetch(`http://backend.vermadesign.in/api/gallery_site/?category=${category}&site_name=${site}`);
            const data = await response.json();
            setCarouselImages(data);
        } catch (error) {
            console.error('Error fetching carousel images:', error);
            alert("No related image found");
        }
    };

    const handleBackToGallery = () => {
        setShowCarousel(false);
        setLeftPanelDisplay('flex'); // Restore left panel display to 'block' when going back
    };

    const renderImagesByCategory = () => {
        const filteredImages = selectedCategory ? images.filter(image => image.category.name === selectedCategory) : images;
        const startIndex = currentPage * dataPerPage;
        const slicedImages = filteredImages.slice(startIndex, startIndex + dataPerPage);
        const pageCount = Math.ceil(filteredImages.length / dataPerPage);
        const handlePageChange = ({ selected }) => {
            setCurrentPage(selected);
        };
        return (

            <React.Fragment>
                <div className='rightpanel'>
                    {slicedImages.map(image => (
                        <div key={image.id} onClick={() => handleImageClick(image.category.name, image.site_name)}>
                            <LazyLoadImage src={`http://backend.vermadesign.in${image.main_image}`} alt={image.site} loading="lazy" />
                            <p style={{ textTransform: 'uppercase' }}>{image.site_name}</p>


                        </div>
                    ))}
                    <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                </div>
            </React.Fragment>
        );
    };

    const renderCategoryButtons = () => {
        const categories = images.reduce((acc, image) => {
            const categoryName = image.category.name;
            acc[categoryName] = true;
            return acc;
        }, {});
        return Object.keys(categories).map(categoryName => (
            <button key={categoryName} onClick={() => handleCategoryClick(categoryName)}>
                {categoryName}
            </button>
        ));
    };


    return (
        <div>
            <div className='cat-container mt-2 container-xxl py-3'>
                <div className='leftpanel' style={{ display: leftPanelDisplay }}>
                    <h2>Gallery</h2>
                    <p>Category</p>
                    {renderCategoryButtons()}
                </div>
                <div className=''>
                    <div>
                        {showCarousel ? (
                            <div className="gallery-carousel">
                                <Carousel images={carouselImages} onBack={handleBackToGallery} />
                            </div>
                        ) : (
                            renderImagesByCategory()
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;
