import React, { useState, useEffect, useLayoutEffect } from 'react';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
require('./OurTeam.css');

function OurTeam() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    // Fetch data from the API
    fetch('http://backend.vermadesign.in/api/ourteam/')
      .then(response => response.json())
      .then(data => {
        setTeamMembers(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useLayoutEffect(() => {
    function updateSlidesToShow() {
      // Adjust slidesToShow based on viewport width
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(4);
      }
    }

    updateSlidesToShow();

    // Event listener for window resize
    window.addEventListener('resize', updateSlidesToShow);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', updateSlidesToShow);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div className='contmain container-xxl py-5'>
      <h1>Our Team</h1>
      <div className="our-team">
        <Slider {...settings} style={{ maxWidth: '100%' }}>
          {teamMembers.map((member, index) => (
            <div className="team-member responsive" key={index}>
              <div className='team-cards responsive'style={{ maxWidth: '100%', minHeight:"80%" }} > 
                <img className='responsive' src={`http://backend.vermadesign.in${member.image}`} alt={member.fullname} />
                <div className="info">
                  <h5>{member.fullname}</h5>
                  <p>{member.post}</p>
                  <p>Exp. {member.experience} Years</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default OurTeam;
