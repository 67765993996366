import React from 'react';
// import "./Services.css";
// const arc_logo = [require('../assets/arc-logo.jpg')]
import apartmentIcon from '../assets/images/icon-apartment.png';
import villaIcon from '../assets/images/icon-villa.png';
import houseIcon from '../assets/images/icon-house.png';
import officeIcon from '../assets/images/icon-housing.png';
import buildingIcon from '../assets/images/icon-building.png';
import townhouseIcon from '../assets/images/icon-neighborhood.png';
import shopIcon from '../assets/images/icon-condominium.png';
import garageIcon from '../assets/images/icon-luxury.png';
function Services() {
    return (

        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                    <h1 className="mb-3 mt-5">Services</h1>
                    <p> Verma Design and Consultant has the expert team in the following field.</p>
                </div>
                <div className="row g-4">
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={apartmentIcon} alt="Apartment" />
                                </div>
                                <h6>Architectural design and consultancy</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={villaIcon} alt="Apartment" />
                                </div>
                                <h6>Interior designing and decoration</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={houseIcon} alt="Apartment" />
                                </div>
                                <h6>Building services and co-ordination</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={officeIcon} alt="Apartment" />
                                </div>
                                <h6>Site supervision and building contracts</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={buildingIcon} alt="Apartment" />
                                </div>
                                <h6>Project and design management</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={townhouseIcon} alt="Apartment" />
                                </div>
                                <h6>Construction and civil engineering services.</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={shopIcon} alt="Apartment" />
                                </div>
                                <h6>Project identification and site selection</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="cat-item d-block bg-light text-center rounded p-3" >
                            <div className="rounded p-4">
                                <div className="icon mb-3">
                                    <img className="img-fluid" src={garageIcon} alt="Apartment" />
                                </div>
                                <h6>Statutory approvals, estimates</h6>
                                {/* <span>click to see our work</span> */}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default Services;
