import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import img from '../assets/images/favicon.jpeg';

function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const navLinksRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 45);
    };

    const handleNavLinkClick = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('scroll', handleScroll);

    const currentNavLinks = navLinksRef.current;

    currentNavLinks.forEach(link => {
      if (link) {
        link.addEventListener('click', handleNavLinkClick);
      }
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      currentNavLinks.forEach(link => {
        if (link) {
          link.removeEventListener('click', handleNavLinkClick);
        }
      });
    };
  }, []);

  return (
    <div className={`container-fluid nav-bar ${isSticky ? 'bg-white sticky-top' : 'bg-transparent'}`}>
      <nav className="navbar navbar-expand-lg navbar-light py-0 px-4">
        <a href="/" className="navbar-brand d-flex align-items-center text-center">
          {/* <div className="icon p-1 me-2"> */}
            <img className="img-fluid" src={img} alt="Icon" style={{ width: '60px', height: '60px', borderRadius: '15px',padding:"5px" }} />
          {/* </div> */}
          <h1 className="m-0 text-primary">VDC</h1>
        </a>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto">
            <NavLink exact className="nav-item nav-link" to="/" ref={el => (navLinksRef.current[0] = el)}>Home</NavLink>
            <NavLink exact className="nav-item nav-link" to="/about" ref={el => (navLinksRef.current[1] = el)}>About</NavLink>
            <NavLink exact className="nav-item nav-link" to="/services" ref={el => (navLinksRef.current[2] = el)}>Services</NavLink>
            <NavLink exact className="nav-item nav-link" to="/gallery" ref={el => (navLinksRef.current[3] = el)}>Projects</NavLink>
            <NavLink exact className="nav-item nav-link" to="/contact" ref={el => (navLinksRef.current[4] = el)}>Contact Us</NavLink>
            <a target="_blank" rel="noopener noreferrer" className="nav-item nav-link" href="https://forms.gle/CF94QdTRdXH3SAci7">Careers</a>
          </div>
          {/* <a href="http://backend.vermadesign.in/admin/" className="btn btn-primary px-3 d-none d-lg-flex">Login</a> */}
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
