import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import img1 from '../assets/images/property-1.jpg'
import img2 from '../assets/images/property-2.jpg'
import img3 from '../assets/images/property-3.jpg'
import img4 from '../assets/images/property-4.jpg'
import img5 from '../assets/images/property-5.jpg'
import img6 from '../assets/images/property-6.jpg'


function Footer() {
  return (
    <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn " data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6  " style={{ fontSize: '12px' }}>
            <h5 className="text-white mb-4">Get In Touch</h5>
            {/* <a href="https://maps.app.goo.gl/h5NJvskhicrumr5M8"><p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>HEAD OFFICE:-99,Dyanand colony, Opp. Sec-5 Police station,Gurgoan,HR,122001</p></a>
           <a href="https://maps.app.goo.gl/h5NJvskhicrumr5M8"><p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>HEAD OFFICE:-99,Dyanand colony, Opp. Sec-5 Police station,Gurgoan,HR,122001</p></a> */}
            <a  href="tel:+919311257550"   ><p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91-9311257550</p></a>
            <a  href="tel:+918400215000"   ><p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+91-8400215000</p></a>
            <a  href="mailto:vermadesignheadoffice@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>vermadesignheadoffice@gmail.com</p></a>
            <a  href="mailto:verma.design@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>verma.design@gmail.com</p></a>
            <a  href="mailto:ar.vdc90@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>ar.vdc90@gmail.com</p></a>
            <a  href="mailto:vermahement90@gmail.com"><p className="mb-2"><i className="fa fa-envelope me-3"></i>vermahement90@gmail.com</p></a>
            <div className="d-flex pt-2">
              {/* <a className="btn btn-outline-light btn-social" href="/"><i className="fab fa-twitter"></i></a> */}
              <a target="_blank" rel="noreferrer" className="btn btn-outline-light btn-social" href="https://www.facebook.com/ar.vdc90"><i className="fab fa-facebook-f"></i></a>
              <a target="_blank" rel="noreferrer" className="btn btn-outline-light btn-social" href="https://www.instagram.com/verma_design_and_consultants?igsh=MWZyaXhucXQ2aG1lcg=="><i className="fab fa-instagram"></i></a>
              <a target="_blank" rel="noreferrer" className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/102769333/admin/feed/posts/?feedType=following"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Quick Links</h5>
            <a href="/about" className="btn btn-link text-white-50" >About</a>
            <a href="/contact" className="btn btn-link text-white-50" >Contact Us</a>
            <a href="/services" className="btn btn-link text-white-50" >Services</a>
            <a href="/gallery" className="btn btn-link text-white-50" >Projects</a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Photo Gallery</h5>
            <a  href="/gallery"><div className="row g-2 pt-2">
              <div className="col-4">
                <img className="img-fluid rounded bg-light p-1" src={img1} alt=""   style={{ height: '80px', objectFit: 'cover', width: '100%' }} />
              </div>
              <div className="col-4">
                <img className="img-fluid rounded bg-light p-1" src={img2} alt=""  style={{ height: '80px', objectFit: 'cover', width: '100%' }}/>
              </div>
              <div className="col-4">
                <img className="img-fluid rounded bg-light p-1" src={img3} alt=""  style={{ height: '80px', objectFit: 'cover', width: '100%' }}/>
              </div>
              <div className="col-4">
                <img className="img-fluid rounded bg-light p-1" src={img4} alt=""  style={{ height: '80px', objectFit: 'cover', width: '100%' }}/>
              </div>
              <div className="col-4">
                <img className="img-fluid rounded bg-light p-1" src={img5} alt=""  style={{ height: '80px', objectFit: 'cover', width: '100%' }}/>
              </div>
              <div className="col-4">
                <img className="img-fluid rounded bg-light p-1" src={img6} alt=""  style={{ height: '80px', objectFit: 'cover', width: '100%' }}/>
              </div>
            </div></a>
          </div>
          <div className="col-lg-3 col-md-6" style={{ fontSize: '12px' }}>
            <h5 className="text-white mb-4">Visit us</h5>
            <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/h5NJvskhicrumr5M8"><p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>HEAD OFFICE:-99, Dayanand Colony, Opp. Sec-5 Police station, Gurugram, HR, 122001</p></a>
            <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Lilac-c/@26.8094588,81.0023569,19z/data=!4m7!3m6!1s0x399be34411e42553:0xad4fedc12455459c!4b1!8m2!3d26.8093732!4d81.0029571!16s%2Fg%2F11gdvwwlqs?entry=ttu"><p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>BRANCH OFFICE :-202, Lilac-C, Omaxe Residancy/1, Gomti Nagar Extn., Lucknow, U.P, 226010</p></a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a className="border-bottom" href="/"> VDC</a>, All Right Reserved.
              Developer <a className="border-bottom" href="https://www.linkedin.com/in/manoj-kumar-430259275" target="_blank" rel="noreferrer">Manoj Kohli</a>
            </div>
            <div className="col-md-6 text-center text-md-end">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;