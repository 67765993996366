import { React } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import Navbar from "./components/Navbar";
import FOOTER from "./components/Footer";
import CONTACT from "./components/ContactSection";
import Services from "./components/ServicesPage";
import Gallery from "./components/Gallery";
import Form from './components/GalleryDataForm';


function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/contact' element={<CONTACT />} />
          <Route path='/services' element={<Services />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/form' element={<Form />} />
        </Routes>
        <FOOTER />
      </div>
    </Router>
  );
}

export default App;
