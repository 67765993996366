import React from 'react';
import aboutImage from '../assets/images/about.jpg';
function AboutPage() {
  return (
    <div className="container-xxl py-5">
      <div className="container ">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6  wow fadeIn" data-wow-delay="0.1s">
            <div className="about-img position-relative overflow-hidden p-5 pe-0">
              <img className="img-fluid w-100" src={aboutImage} alt="" />
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <h1 className="mb-4 mt-5">VERMA DESIGN & CONSULTANT </h1>
            <p className="mb-4">VERMA DESIGN & CONSULTANT is an architectural and interior designing firm located in Gurgaon, Haryana and founded by Mr. O.P. Verma and started its business as sole proprietorship firm in 1996. The firm provides comprehensive services in architectural, engineering consultancy, interior design & construction of all building works encompassing all aspects of the building industry from project identification and conceptual planning to detailed designing, building services, co-ordination and construction monitoring, which are relevant to the demands of the project and client needs. The firm is owned & managed by Mr. O.P. VERMA, who himself is an experienced person with an experience of 30 years in the field of architecture & construction and AR. HEMANT KUMAR (Lead Architect), who also has an experience of 10 years in the architectural field.<br /> <br />
              VERMA DESIGN & CONSULTANT comprises of dedicated professionals with analytical and designing skills, social, sensitivity, experience and objectivity needed to a wide spectrum of clients in a variety of specialized area supported by experienced consultants in multi-disciplines. The firm has designed, co-ordinate and executed complex projects through on innovative, time quality and cost conscious and result oriented approach.
              <br /> <br />VERMA DESIGN & CONSULTANT endeavor has always been to evolve design methodologies, which are related to environmental friendly, climatic responsive, locally available material & manpower, social treads, technology development and above all energy efficient and also works on green building concept. Our philosophy is to analyze the project needs and to evolve the form accordingly and handling over to the users finally with full satisfaction of value for money. The firm has attempted in its projects to use alternative energy sources, pre-fabrication, cost efficient methods of construction etc. to ensure that the build firm is in consonance with not only our environment but also with the various social realities. </p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
