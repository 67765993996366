import React, { Suspense, useState, useEffect } from 'react';
import Spinner from './Spinner'; // Import the Spinner component

// import Carousel from './Carousel';
import AboutSection from "./AboutSection"
import ServicesPage from "./ServicesPage"
import OurTeam from "./OurTeam"
import Appointment from "./Appoitment.jsx"

const Carousel = React.lazy(() => import('./Carousel'));

function HomePage() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const images = document.querySelectorAll('img');
        let loadedCount = 0;

        const handleImageLoad = () => {
            loadedCount++;
            if (loadedCount === images.length) {
                setLoading(false);
            }
        };

        images.forEach(image => {
            if (image.complete) {
                handleImageLoad();
            } else {
                image.addEventListener('load', handleImageLoad);
            }
        });

        return () => {
            images.forEach(image => {
                image.removeEventListener('load', handleImageLoad);
            });
        };
    }, []);

    return (
        <div className="home-page">
            <Suspense fallback={<Spinner />}>
                <Carousel />
            </Suspense>
            <AboutSection />
            <ServicesPage />
            <Appointment />
            <OurTeam />
            {loading && <Spinner />}
        </div>
    );
}

export default HomePage;
